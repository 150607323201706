@import "./variables.scss";

.modal-grid {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #1d1e20dd;
    backdrop-filter: blur(15px);
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-template-rows: 1fr auto 1fr;
    color: #000;
    z-index: 100;

    .modal-container {
        grid-column: 2;
        grid-row: 2;
        //   background: linear-gradient(135deg, #01cbb7 0%, #00796d 60.42%, #00655b 100%);
        background: #1c222e;
        border-radius: 0.25rem;
        box-shadow:
            0px 5px 22px 0px rgba(0, 0, 0, 0.03),
            0px 7px 8px 0px rgba(0, 0, 0, 0.05);

        @media screen and (max-width: $tablet) {
            margin: 2.5rem;
        }
    }

    button.btn {
        margin: 0 auto;
        margin-bottom: 3rem;
        padding: 0.5rem 2rem;
        display: block;
        appearance: none;
        font-family: "Hind", sans-serif;
        font-weight: 700;
        font-size: 0.88rem;
        line-height: 1;
        border: 2px solid #fff;
        border-radius: 40px;
        background-color: transparent;
        color: #fff;
        text-decoration: none;

        &:hover,
        &:focus {
            background-color: #fff;
            color: #05112a;
        }
    }
}

.feedback-header {
    display: flex;
    background: #6d7078;
    padding: 1rem 2rem;
    border-top-right-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
    justify-content: space-between;
    vertical-align: middle;
    h2 {
        margin: 0;
        padding: 0;
        color: #fff;
        @include TTCommon();
        align-self: center;
    }

    .close-btn {
        padding: 9px;
        background-color: #fff0;
        transition: background-color 0.2s ease;
        border-radius: 50%;
        border: none;

        svg {
            display: block;
            fill: #fff8;
            transition: fill 0.2s ease;
        }

        &:hover,
        &:focus {
            background-color: #fff8;

            svg {
                fill: #333;
            }
        }
    }
}

#feedback-form {
    overflow-y: auto;
    max-height: 90vh;
    scrollbar-width: thin; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */

    &::-webkit-scrollbar {
        width: 5px; /* Set the width of the scrollbar */
    }

    &::-webkit-scrollbar-thumb {
        background-color: transparent; /* Set the color of the scrollbar thumb to transparent */
    }

    section.feedback-body {
        padding: 3rem 5rem 0 5rem;
        @media screen and (max-width: $tablet) {
            padding: 3rem 3rem 0 3rem;
        }
        @media screen and (max-width: $mobile) {
            padding: 3rem 1.5rem 0 1.5rem;
        }
        h1 {
            @include TTCommon();
            font-size: 2.625rem;
            color: #fff;
        }

        .question {
            margin: 2rem 0;
            label {
                @include TTCommon();
                font-size: 1rem;
                color: #fff;
            }

            .feedback-select {
                background-color: #fff;
                margin-top: 0.5rem;
                border-radius: 0.25rem;
                padding: 0.75rem 1rem;
                color: $textDark !important;
                font-size: 1rem;
                border: 1px solid #878d99;
                &:focus,
                &:active {
                    outline: 3px solid #b721ed;
                }
                &::placeholder {
                    // Correct syntax for placeholder styling
                    border: 1px solid red;
                    color: red;
                }
            }

            .react-dropdown-select-item {
                padding: 0.5rem 1rem;
            }
            .react-dropdown-select-item.react-dropdown-select-item-selected,
            .react-dropdown-select-item.react-dropdown-select-item-active {
                background-color: rgba(199, 199, 199, 0.3);
                color: $textDark;
                font-weight: bold;
            }
            .react-dropdown-select-placeholder {
                color: #878d99;
            }
        }

        textarea {
            padding: 0.25rem 0.5rem;
            margin-top: 0.5rem;
            width: 100%;
            height: 10rem;
            border-radius: 0.25rem;
            font-size: 1rem;
            line-height: 1.5;
            border: 1px solid #878d99;
            @include Hind();
            &:focus,
            &:active {
                outline: 3px solid #b721ed;
            }
        }

        .radio-group {
            margin: 0.75rem 0;
            label {
                margin: 0 1.5rem 0 0.25rem;
            }
            input[type="radio"] {
                cursor: pointer;
                position: absolute;
                opacity: 0;
                height: 0;
                width: 0;

                + label {
                    cursor: pointer;
                    position: relative;
                    padding-left: 30px;
                    line-height: 20px;
                    display: inline-block;

                    &:before {
                        content: "";
                        position: absolute;
                        top: 50%;
                        left: 0;
                        transform: translateY(-50%);
                        width: 20px;
                        height: 20px;
                        border-radius: 50%;
                        background-color: #ffffff;
                        border: 1px solid #919191;
                    }
                }

                &:checked + label:before {
                    border: 6px solid #e5067c;
                }
            }
        }

        .leave-name {
            display: flex;
            flex-direction: column;
            label.email {
                margin-top: 0.5rem;
            }
            input[type="text"] {
                margin: 0.5rem 0;
                border-radius: 0.25rem;
                padding: 0.75rem 1rem;
                color: $textDark !important;
                font-size: 1rem;
                appearance: none;
                border: 1px solid #878d99;
                &:focus,
                &:active {
                    outline: 3px solid #b721ed;
                }
            }
        }
    }

    p {
        margin-bottom: 2rem;
        color: #fff;
    }
}

.thanks {
    .feedback-body {
        padding: 3rem 5rem 1.5rem 5rem;
        h1 {
            @include TTCommon();
            font-size: 2.5rem;
            color: #fff;
            text-wrap: wrap;
            max-width: 30rem;
            text-align: center;
        }
        @media screen and (max-width: $tablet) {
            padding: 3rem 1.5rem 0 1.5rem;
        }
    }
}

.feedback-btn-container {
    position: fixed;
    top: 66%;
    right: 0;
    min-width: 40px;
    max-width: 50px;
    cursor: default;
    background-color: #cccccc;

    a.feedback-btn {
        padding: 1rem 0.75rem;
        display: block;
        writing-mode: vertical-rl;

        color: #000;
        opacity: 0.5;
        transition: all 0.25s ease-out;
        text-decoration: none;
        p {
            margin: 0;
            padding: 0;
            @include TTCommon();
            font-size: 1rem;
        }

        &:hover,
        &:focus {
            background-color: #fefefe;
        }
    }
}
