@import './variables.scss';

table.table-job-scope{
    max-width:1600px;
    width: 100%;
    margin: 0 auto;
    color: $textWhite;
    font-size: 1rem;
    border-spacing: 8px;
    padding-top: 2.5rem;

    thead {

        th {
          padding: 17px;
          background-color: #E8EEFA;
          color: #333;
          text-transform: uppercase;
          font-family: 'Hind', sans-serif;
          font-size: .0.875rem;
          font-weight: 700;
          border-radius: 8px 8px 0 0;
          width: 16.6%;
          padding: 0;
          &:first-child{
              background-color: transparent;
          }
        }
      }
      tbody {
        tr {
            padding: 12px;
            background-color: #383E4A;
            @include Hind();
            font-size: 0.875rem;
            line-height: 1.27;
            font-weight: 400;
            vertical-align: top;
            &:first-child {
                td{
                    border-radius: 8px 0 0 0;
                }
            }
            &:last-child {
                td{
                    border-radius: 0 0 0 8px;
                }
            }
       
            td{
                padding: 16px 16px 28px;
                background-color: #383E4A;
                font-size: .77rem;
                line-height: 1.5;
                vertical-align: top;
                &:first-child p {
                    @include TTCommon();
                    font-weight: 700 !important;
                    font-size: 1.37rem !important;
                    text-align: center;
                  }
            }
            p {
                margin: 0;
                padding: 0;
                @include Hind();
                font-weight: 400;
                font-size: 0.875rem;
            }
                    
           td:not(:first-child) {
                 border-radius: 0;
           }
        }


    tr.last-row { 
        ul {
            margin: 0;
            width: 45%;
            display: inline-block;
            vertical-align: top;
          }
      }  
  }
}
@media print {
    table.table-job-scope {
      thead {
        th:not(:empty) {
          filter: brightness(90%);
        }
      }
  
      tbody {
        th {
          filter: brightness(300%);
        }
        td {
          filter: brightness(300%);
        }
      }
    }
  }
  
